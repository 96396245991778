import { axiosInstance } from "../../Axios";
import { TOOL_TRANSFER_CONSTANT } from "../../reducers/procurement/toolTransferReducer";
import { handleGetCurrentDateUtils } from "../../utils/WeekNumbre";

export const requestToolTransferAction =
  (admin,notify, history) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;
    const { toolRequest } = await getState().toolTransferReducer;
    axiosInstance
      .post("procurement/create_tool_request/", {
        ...toolRequest,
        requested_from:admin,
        location,
        site,
      })
      .then((res) => {
        if (res.data.message) {
          notify(res.data.message, { variant: "success" });
          history.goBack();
        } else {
          notify("Tool Request Failed", { variant: "error" });
        }
      })
      .catch((err) => {
     
        console.log("Dashboard Error !", err);
      });
  };

export const handleToolStatusPartiallAction =
  (pk, notify, history) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;
    const { toolRequest } = await getState().toolTransferReducer;
    delete toolRequest.status_list;
    axiosInstance
      .put(`procurement/tool_request_approval/${pk}/`, {
        ...toolRequest,
        status: "Partial Approved", 
        location,
        site,
      })
      .then((res) => {
        if (res.data.message) {
          notify(res.data.message, { variant: "success" });
          history.go(0);
        } else {
          notify("Tool Request Failed", { variant: "error" });
        }
      })
      .catch((err) => {
        notify("Data Load Failed . Try Again", { variant: "error" });
        console.log("Dashboard Error !", err);
      });
  };

  export const handleToolStatusApproveAction =
  (pk, notify, history) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;
    const { toolRequest } = await getState().toolTransferReducer;
    delete toolRequest.status_list;
    axiosInstance
      .put(`procurement/tool_request_approval/${pk}/`, {
        ...toolRequest,
        tool_request_line: toolRequest.tool_request_line.map((val) => ({
          ...val,
          approved_by: "",
        })),
        status: "Approved", 
        location,
        site,
      })
      .then((res) => {
        if (res.data.message) {
          notify(res.data.message, { variant: "success" });
          history.go(0);
        } else {
          notify("Tool Request Failed", { variant: "error" });
        }
      })
      .catch((err) => {
        notify("Data Load Failed . Try Again", { variant: "error" });
        console.log("Dashboard Error !", err);
      });
  };

export const getToolTransferTableAction =
  (notify) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;
    const procurement_admin = await getState().user.procurement_admin;
    const {
      tool_transfer_no,
      status,
      item,
      sku_code,
      on_page_data_client,
      pg_no,
    } = await getState().toolTransferReducer.toolTable;
    axiosInstance
      .post("procurement/tool_request_table_data/", {
        tool_transfer_no,
        status,
        on_page_data: on_page_data_client,
        pg_no,
        item,
        sku_code,
        location,
        site: procurement_admin ? "" : site,
      })
      .then((res) => {
        dispatch({
          type: TOOL_TRANSFER_CONSTANT.TOOL_TRANSFER_TABLE,
          payload:  res.data ,
        });
      })
      .catch((err) => {
        notify("Data Load Failed . Try Again", { variant: "error" });
        console.log("Dashboard Error !", err);
      });
  };

export const getToolTransferPerIDAction = (pk, notify) => async (dispatch) => {
  axiosInstance
    .get(`procurement/get_tool_request_data/${pk}/`)
    .then((res) => {
      dispatch({
        type: TOOL_TRANSFER_CONSTANT.TOOL_TRANSFER_REQUEST_FORM,
        payload: res.data,
      });
    })
    .catch((err) => {
      notify("Data Load Failed . Try Again", { variant: "error" });
      console.log("Dashboard Error !", err);
    });
};

export const approveToolRequestAction =
  (line_pk, notify) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;

    axiosInstance
      .put(`procurement/tool_request_approval/${line_pk}/`, {
        location,
        site,
      })
      .then((res) => {
        notify("Data Approval Successfull", { variant: "sucess" });
        dispatch({
          type: TOOL_TRANSFER_CONSTANT.TOOL_TRANSFER_GET_TOOL_INIT,
        });
      })
      .catch((err) => {
        notify("Data Request Approval Failed. Try Again", { variant: "error" });
        dispatch({
          type: TOOL_TRANSFER_CONSTANT.TOOL_TRANSFER_GET_TOOL_INIT,
        });
        console.log("Dashboard Error !", err);
      });
  };

export const getToolTransferNoAction = () => async (dispatch, getState) => {
  const location = await getState().user.location;
  const site = await getState().user.site;

  axiosInstance
    .post(`procurement/procurement_dropdown/`, {
      fields: ["tool_transfer_no","admin_sites"],
      location,
      site,
    })
    .then((res) => {
      dispatch({
        type: TOOL_TRANSFER_CONSTANT.TOOL_TRANSFER_REQUEST_FORM,
        payload: {
          tool_transfer_no: res.data.tool_transfer_no,
          admin_sites: res.data.admin_sites,
          date: handleGetCurrentDateUtils(),
        },
      });
    })
    .catch((err) => {
      console.log("Dashboard Error !", err);
    });
};
